<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :disabled="loading"
        :loading="loading"
        color="success" 
        icon
        @click="resetPassword"
      >
        <v-icon>fal fa-sync</v-icon>
      </v-btn>
    </template>
    <span>Resetare parola</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ResetPasswordButton',
  inject: ['table'],
  data () {
    return {
      loading: false
    }
  },
  props: {
    user: {
      required: true
    }
  },
  methods: {
    resetPassword () {
      this.loading = true
      this.$http.patch(`users/${this.user.id}/reset-password`)
        .then(() => {
          this.$vs.notify({
            title: 'Succes',
            text: 'Parola a fost resetata cu succes',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la resetare. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

